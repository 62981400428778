/* eslint-disable max-len, import/no-cycle, no-use-before-define */
import Vue from 'vue';
import { reactive, toRefs, computed } from '@vue/composition-api';
import useMachine from '@/composables/useMachine';
import $http from 'axios';
import i18n from '@/plugins/i18n';

const state = reactive({
    orderOverviewDrawerActive: false,
    appliedFilters: [],
    cart: [],
    appliedCoupon: null,
    cartAmount: computed(() => state.cart.length),
    checkoutDrawerActive: false,
    paymentMethod: null,
    bankSelectDrawerActive: false,
    idealBank: null,
    vendingId: null,
    idealBanks: [],
    fridgeSessionId: null,
    isFridgeExtraSession: false,
});

export default function useOrder() {
    const { code: machineNumber, uniqueFilledSlots, machine: machineData } = useMachine();

    /**
     * Method to toggle the order overview/cart drawer
     * @returns {void}
     */
    const toggleOrderOverviewDrawer = () => {
        state.orderOverviewDrawerActive = !state.orderOverviewDrawerActive;
    };

    /**
     * Computed property to calculate the size of the cart
     * @returns {Number} Total price
     */
    const totalCartSize = computed(() => {
        const amountMappedCart = state.cart.map((cartItem) => cartItem.amount);

        return amountMappedCart.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    });

    /**
     * Method to add a product to the cart
     * @returns {void}
     */
    const addProductToCart = (slotId, productId, isFridgeSession) => {
        const existingCartItem = state.cart.find((item) => item.slot === slotId);

        // Add to cart or increase amount if already present
        if (existingCartItem) {
            existingCartItem.amount += 1;
        } else {
            state.cart.push({
                slot: slotId,
                product: productId,
                amount: 1,
            });
        }

        // Open the order overview drawer
        if (!isFridgeSession) {
            state.orderOverviewDrawerActive = true;
        }
    };

    /**
     * Method to remove a product to the cart
     * @returns {void}
     */
    const removeProductFromCart = (slotId) => {
        const cartItemIndex = state.cart.findIndex((item) => item.slot === slotId);

        // If cart item was found, remove from cart
        if (cartItemIndex !== -1) {
            state.cart.splice(cartItemIndex, 1);
        }
    };

    /**
     * Computed property to calculate the total price of the cart
     * @returns {Number} Total price
     */
    const totalPrice = computed(() => {
        const totalPricePerProduct = state.cart.map((cartItem) => {
            const slotData = uniqueFilledSlots.value.find((slot) => slot.slot === cartItem.slot);

            // Return the product price times the cart amount for this product
            return (Number(slotData.price) + slotData.deposit) * cartItem.amount;
        });

        // Calculate sum of all product totals
        const totalCartPrice = totalPricePerProduct.reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
        );

        return totalCartPrice;
    });

    /**
     * Computed property to calculate total price with discount applied
     * @returns {Number} Total price
     */
    const totalPriceWithDiscount = computed(() => {
        let defaultTotalPrice = totalPrice.value;

        // Check if a percentage discount coupon is applied
        if (state.appliedCoupon?.type === 'percentage') {
            const discount = state.appliedCoupon.value / 100;
            defaultTotalPrice -= (defaultTotalPrice * discount);
        }

        // Check if a fixed discount coupon is applied
        if (state.appliedCoupon?.type === 'fixed') {
            defaultTotalPrice -= state.appliedCoupon.value;
        }

        return Number(defaultTotalPrice >= 0 ? defaultTotalPrice.toFixed(2) : 0);
    });

    /**
     * Computed property to calculate the total deposit of the cart
     * @returns {Number} Total deposit
     */
    const totalDeposit = computed(() => {
        const totalDepositPerProduct = state.cart.map((cartItem) => {
            const slotData = uniqueFilledSlots.value.find((slot) => slot.slot === cartItem.slot);
            // Return the product price times the cart amount for this product
            return slotData.deposit * cartItem.amount;
        });

        // Calculate sum of all product totals
        const totalCartDeposit = totalDepositPerProduct.reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
        );

        return totalCartDeposit;
    });

    /**
     * Computed property to get the value of the applied discount
     * @returns {Number} Discount value
     */
    const discountValue = computed(() => {
        if (state.appliedCoupon?.type === 'percentage') {
            return -Math.abs(totalPrice.value * (state.appliedCoupon.value / 100));
        }

        if (state.appliedCoupon?.type === 'fixed') {
            return -Math.abs(state.appliedCoupon.value);
        }

        return 0;
    });

    /**
     * Computed property to check if free vending is active
     * @returns {Boolean} Is free vending active
     */
    const isFreeVending = computed(() => {
        return machineData.value.has_free_vending || totalPrice.value === 0;
    });

    /**
     * Method to toggle the payment method drawer
     * @returns {void}
     */
    const togglePaymentMethodDrawer = () => {
        state.checkoutDrawerActive = !state.checkoutDrawerActive;
    };

    /**
     * Method to save cart to local storage
     *
     * In case the payment fails, the user will be redirected to
     * the /connect?machine=ID route to automatically reconnect, then
     * cart will be fetched from local storage so the user can retry
     * the checkout process without having to add the products again
     *
     * @returns {void}
     */
    const saveCartInLocalStorage = () => {
        localStorage.setItem('order-cart', JSON.stringify(state.cart));
    };

    /**
     * Method to reset the order state after disconnect
     * @returns {void}
     */
    const resetOrderState = () => {
        state.appliedFilters = [];
        state.cart = [];
        state.appliedCoupon = null;
        state.paymentMethod = null;
        state.idealBank = null;
        state.fridgeSessionId = null;

        localStorage.removeItem('order-cart');
    };

    /**
     * Method to submit the order to back-end
     * @returns {void}
     */
    const submitOrder = async () => {
        saveCartInLocalStorage();

        const isFreeVending = machineData.value.has_free_vending || totalPrice.value === 0;

        if (isFreeVending) {
            state.paymentMethod = 'free';
            console.info('Free Vending is Active');
        }

        const orderBody = {
            machine_number: machineNumber.value,
            session_type: 'regular',
            base_url: process.env.VUE_APP_BASE_URL,
            payment_method: state.paymentMethod,
            items: state.cart,
            ...(state.paymentMethod === 'ideal' && {
                issuer: state.idealBank,
            }),
            ...(state.appliedCoupon && {
                coupon_code: state.appliedCoupon.code,
            }),
            vending_session_id: state.fridgeSessionId,
        };

        try {
            const response = await $http.post('/order/checkout', orderBody);

            // Redirect to checkout page if url is present in response.
            if (response.data?.url) {
                resetOrderState();
                toggleOrderOverviewDrawer();

                localStorage.setItem('processing-payment', true);

                window.location.replace(response.data.url);
            }
        } catch (error) {
            if (error.response.status === 422 && error.response.data.error_type === 'machine-in-use') {
                Vue.$toast.open({
                    message: i18n.t('machine-in-use-error'),
                    type: 'warning',
                    duration: 5000,
                });
            } else if (error.response.data.message) {
                Vue.$toast.open({
                    message: error.response.data.message,
                    type: 'error',
                    duration: 5000,
                });
            } else {
                Vue.$toast.open({
                    message: i18n.t('default-error', {
                        error: 'Server error',
                    }),
                    type: 'error',
                    duration: 5000,
                });
            }

            if (!isFreeVending) {
                togglePaymentMethodDrawer();
            }

            toggleOrderOverviewDrawer();
        }
    };

    /**
     * Method to toggle the payment method drawer
     *
     * @param {String} paymentMethod Selected payment method (e.g. ideal)
     * @returns {void}
     */
    const paymentMethodPicked = (paymentMethod) => {
        state.paymentMethod = paymentMethod;

        // Show bank selector drawer if payment method is iDeal
        if (paymentMethod === 'ideal') {
            state.bankSelectDrawerActive = true;
        } else {
            submitOrder();
        }
    };

    /**
     * Method to toggle the iDeal bank selector method drawer
     * @returns {void}
     */
    const toggleBankSelectDrawer = () => {
        state.bankSelectDrawerActive = !state.bankSelectDrawerActive;
    };

    /**
     * Callback after iDeal bank was select
     * @returns {void}
     */
    const idealBankSelected = async (idealBank) => {
        state.idealBank = idealBank;

        await submitOrder();

        // Close all checkout drawers
        // toggleBankSelectDrawer();
        // togglePaymentMethodDrawer();
        // toggleOrderOverviewDrawer();
    };

    /**
     * Method to apply cart from local storage
     *
     * @see saveCartInLocalStorage
     * @returns {void}
     */
    const applyCartFromLocalStorage = () => {
        const savedCartData = localStorage.getItem('order-cart');

        if (savedCartData) {
            const parsedCartData = JSON.parse(savedCartData);

            // Loop trough all found cart items from local storage
            parsedCartData.forEach((cartItem) => {
                const relatedSlot = uniqueFilledSlots.value.find((slot) => slot.slot === cartItem.slot);

                // If the cart item amount is in stock, add to cart
                if (relatedSlot && relatedSlot.stock >= cartItem.amount) {
                    state.cart.push(cartItem);
                } else if (relatedSlot && relatedSlot.stock >= 1) {
                    /**
                     * If the exact amount is not in stock, but there is at least 1 product
                     * in stock, add to cart with maximum amount available
                     */
                    state.cart.push({
                        slot: cartItem.slot,
                        product: cartItem.product,
                        amount: relatedSlot.stock,
                    });
                }
            });
        }
    };

    return {
        ...toRefs(state),
        toggleOrderOverviewDrawer,
        totalCartSize,
        addProductToCart,
        removeProductFromCart,
        totalPrice,
        totalPriceWithDiscount,
        discountValue,
        totalDeposit,
        togglePaymentMethodDrawer,
        paymentMethodPicked,
        toggleBankSelectDrawer,
        idealBankSelected,
        applyCartFromLocalStorage,
        resetOrderState,
        isFreeVending,
        submitOrder,
    };
}
